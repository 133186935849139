import React, { useMemo, useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Spinner,
  Label,
  Input,
  Button,
  Form,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  getAlternatives,
  getWarehousesOrigin,
  getWarehousesDestination,
  getSku,
  setReplacements,
} from "../../redux/actions/stocks";

import "./Stock.css";
import trashIcon from "../../assets/img/icons/trash.svg";
import lockIcon from "../../assets/img/icons/lock.svg";
import unlockIcon from "../../assets/img/icons/unlock.svg";
import starIcon from "../../assets/img/icons/star.svg";

let skuTimer = undefined;

function Stock() {
  const [isLoading, setLoading] = useState(false);
  // const [origin, setOrigin] = useState('PN8000');
  // const [destination, setDestination] = useState('CURAUMA');
  // const [pallets, setPallets] = useState('100');
  // const [missingSku, setMissingSku] = useState('450235');
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [pallets, setPallets] = useState("");
  const [missingSku, setMissingSku] = useState("");
  const [unlockComment, setUnlockComment] = useState("Diferencia WMS");
  const [alternatives, setAlternatives] = useState([]);
  const [product, setProduct] = useState({});
  const [alertMessage, setAlertMessage] = useState();
  const [warehousesOrigin, setWarehousesOrigin] = useState([]);
  const [warehousesDestination, setWarehousesDestination] = useState([]);
  const [rulesEnabled, setRulesEnabled] = useState(true);
  const [totalAdded, setTotalAdded] = useState(0);
  const [unlockModal, setUnlockModal] = useState(false);
  const [oca, setOca] = useState("");
  const [validOca, setValidOca] = useState(false);

  const loadWarehouses = useMemo(
    () => () => {
      getWarehousesOrigin()
        .then((data) => {
          setWarehousesOrigin(data.map((w) => ({ value: w, label: w })));
          setAlertMessage();
        })
        .catch((error) => {
          const { response: { data: { message } } = { data: {} } } = error;
          if (message) {
            setAlertMessage({ message });
          } else {
            console.error(error);
          }
        });
      getWarehousesDestination()
        .then((data) => {
          setWarehousesDestination(data.map((w) => ({ value: w, label: w })));
          setAlertMessage();
        })
        .catch((error) => {
          const { response: { data: { message } } = { data: {} } } = error;
          if (message) {
            setAlertMessage({ message });
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const searchSku = (query) => {
    getSku(query)
      .then((data) => {
        setProduct(data);
      })
      .catch(() => {
        setProduct({ invalid: true });
      });
  };

  const onSkuType = (query) => {
    setMissingSku(query);
    if (skuTimer) clearTimeout(skuTimer);
    skuTimer = setTimeout(() => {
      searchSku(query);
    }, 800);
  };

  const maxToAdd = (a) =>
    Math.min(
      a.stock_o - (a.amount || 0),
      pallets - totalAdded,
      a.max_to_replace - (a.amount || 0)
    );
  const maxTohave = (a, left = pallets) =>
    Math.min(a.stock_o, a.max_to_replace, left);

  const searchAlternatives = () => {
    setLoading(true);
    getAlternatives({ sku: missingSku, origin, destination, pallets })
      .then((data) => {
        if (data.error) {
          setAlertMessage({ message: alternatives.status });
          setAlternatives([]);
          setTotalAdded(0);
        } else {
          if (data.alternatives.length === 0) {
            const resMessage =
              data.message || "No hay alternativas disponibles";
            setAlertMessage({
              message: `${resMessage}. Contactar a equipo de planificación. ${data.alert}`,
              color: "info",
            });
          } else {
            setAlertMessage();
          }
          setAlternatives(
            data.alternatives.map((alt, i) => ({ ...alt, enabled: i === 0 }))
          );
          setProduct(data.product);
          setTotalAdded(0);
        }
      })
      .catch((error) => {
        const { response: { data: { message } } = { data: {} } } = error;
        if (message) {
          setAlertMessage({ message });
        } else {
          console.error(error);
          setAlertMessage({ message: "Ha ocurrido un error inesperado" });
        }
        setAlternatives([]);
      })
      .finally(() => setLoading(false));
  };

  const addAlternative = (sku, quantity) => {
    const index = alternatives.findIndex((p) => p.sku === sku);
    if (index < 0) return;
    if (index > 0 && !alternatives[index].enabled) return;

    let amount = alternatives[index].amount || 0;
    let max = maxToAdd(alternatives[index]);
    if (
      quantity <= max ||
      !rulesEnabled ||
      (quantity < 0 && quantity + amount >= 0)
    ) {
      amount += quantity;
    }
    let newAlternatives = [...alternatives];
    newAlternatives[index] = { ...alternatives[index], amount };
    let left = pallets;
    newAlternatives = newAlternatives.map((p, index, alts) => {
      p.enabled =
        index === 0 || !rulesEnabled
          ? true
          : alts[index - 1].enabled && alts[index - 1].amount > 0;
      if (!p.enabled) p.amount = 0;
      else if (rulesEnabled)
        p.amount = Math.min(maxTohave(alternatives[index], left), p.amount);
      left -= p.amount;
      return p;
    });
    setTotalAdded(newAlternatives.reduce((sum, p) => sum + (p.amount || 0), 0));
    setAlternatives(newAlternatives);
  };

  const toggleRules = () => setRulesEnabled((e) => !e);
  const toggleModal = () => setUnlockModal((s) => !s);

  const submitSelection = () => {
    if (rulesEnabled && addedClass === "error") return;
    if (!product) return;

    if (!unlockModal) toggleModal();
    if (!validOca) return;

    const missing = {
      origin,
      destination,
      sku: product.sku,
      name: product.name,
      comment: unlockComment,
      oca,
    };
    const selected = alternatives
      .filter((a) => a.amount > 0)
      .map((a) => ({
        sku: a.sku,
        name: a.name,
        pallets: a.amount,
        priority: a.priority,
      }));
    setReplacements(missing, selected)
      .then(() => {
        setAlertMessage({
          message: "¡Reemplazos guardados exitosamente!",
          color: "success",
        });
        setOrigin("");
        setDestination("");
        setPallets("");
        setMissingSku("");
        setAlternatives([]);
        setProduct({});
        setUnlockComment("Diferencia WMS");
        setOca("");
        setValidOca(false);
        setUnlockModal(false);
      })
      .catch((error) => {
        const { response: { data: { message } } = { data: {} } } = error;
        if (message) {
          setAlertMessage({ message });
        } else {
          setAlertMessage({ message: "Ha ocurrido un error inesperado" });
          console.error(error);
        }
      });
  };

  const submitReplacement = () => {
    submitSelection();
  };

  useEffect(() => {
    loadWarehouses();
  }, [loadWarehouses]);

  useEffect(() => {
    if (alternatives.length > 0) addAlternative(alternatives[0].sku, 0);
  }, [rulesEnabled]);

  useEffect(() => {
    setValidOca(oca.length > 1);
  }, [oca]);

  let addedClass = "error";
  if (totalAdded > pallets || totalAdded < 1) {
    addedClass = "error";
  } else if (totalAdded >= pallets * 0.9) {
    addedClass = "success";
  } else if (totalAdded >= pallets * 0.7) {
    addedClass = "warning";
  }

  return (
    <Container>
      {alertMessage && alertMessage.message && (
        <Alert color={alertMessage.color || "danger"}>
          {alertMessage.message}
        </Alert>
      )}
      <div className="row">
        <div className="col-12">
          <Form className="row alternatives-form">
            <div className="col-md-2">
              <Label>SKU Faltante</Label>
              <Input
                type="text"
                style={{ height: "38px" }}
                value={missingSku}
                onChange={(e) => onSkuType(e.target.value)}
              />
              <div
                style={{
                  fontSize: "0.7rem",
                  lineHeight: "0.8rem",
                  margin: "5px 2px",
                }}
              >
                {product && product.invalid && (
                  <div style={{ color: "red" }}>SKU inválido</div>
                )}
                {product && product.name && <div>{product.name}</div>}
                {product && product.cxp && <div>CxP: {product.cxp}</div>}
              </div>
            </div>
            <div className="col-md-2">
              <Label>Pallets Faltantes</Label>
              <Input
                type="number"
                style={{ height: "38px" }}
                value={pallets}
                onChange={(e) => setPallets(e.target.value)}
              />
              {alternatives && alternatives.length > 0 && (
                <div className={`pallets-info ${addedClass}`}>
                  {totalAdded}/{pallets}
                </div>
              )}
            </div>
            <div className="col-md-3">
              <Label>Depósito de Salida</Label>
              <Select
                options={warehousesOrigin}
                isSearchable={true}
                isClearable={true}
                value={{ label: origin, value: origin }}
                onChange={(selected) =>
                  setOrigin(selected ? selected.value : null)
                }
              />
            </div>
            <div className="col-md-3">
              <Label>Depósito de Llegada</Label>
              <Select
                options={warehousesDestination}
                isSearchable={true}
                isClearable={true}
                value={{ label: destination, value: destination }}
                onChange={(selected) =>
                  setDestination(selected ? selected.value : null)
                }
              />
            </div>
            <div className="col-md-2" style={{ paddingTop: "19px" }}>
              <Button
                className="btn btn-primary"
                onClick={() => searchAlternatives()}
              >
                Buscar
              </Button>
            </div>
          </Form>
        </div>

        <div className="col-12 col-lg-12 col-md-12 mb-5">
          {isLoading ? (
            <Container>
              <Spinner
                style={{ position: "fixed", top: "47%", left: "47%" }}
                color="primary"
              />
            </Container>
          ) : (
            <>
              {alternatives.length > 0 && (
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-2">
                    <div
                      className="btn icon-btn btn-warning my-0"
                      onClick={() => toggleRules()}
                    >
                      {rulesEnabled ? (
                        <>
                          Liberar <img src={unlockIcon} alt="ícono candado" />
                        </>
                      ) : (
                        <>
                          Restringir <img src={lockIcon} alt="ícono candado" />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <Button
                      className="btn btn-primary my-0"
                      disabled={
                        totalAdded < 1 ||
                        (rulesEnabled && addedClass === "error")
                      }
                      onClick={() => submitSelection()}
                    >
                      Aplicar
                    </Button>
                  </div>
                </div>
              )}
              <div className="alternatives row gx-2">
                {alternatives.map((d) => {
                  let maxAdd = maxToAdd(d);
                  const delEnabled = d.amount > 0;
                  const addEnabled = maxAdd > 0 || !rulesEnabled;
                  const maxDelEnabled = d.amount;
                  const maxAddEnabled = d.enabled && maxAdd > 0;
                  return (
                    <div key={d.sku} className="col-md-3">
                      <div className="card border border-success alternative">
                        <div className="card-body">
                          <div className="card-title">
                            {d.sku}
                            {d.produced_at && (
                              <img src={starIcon} alt="Producido en orígen" />
                            )}
                          </div>
                          <p className="card-text product-name">{d.name}</p>
                          <div className="card-kpis">
                            <p className="card-text">Prioridad: {d.priority}</p>
                            <p className="card-text">Stock O: {d.stock_o}</p>
                            <p className="card-text">Stock D: {d.stock_d}</p>
                            <p className="card-text">Forecast: {d.forecast}</p>
                            <p className="card-text">
                              DV: {d.dv === null ? "-" : d.dv}
                            </p>
                            <p className="card-text">
                              Cumpl.:{" "}
                              {d.cumplimiento === null
                                ? "-"
                                : `${d.cumplimiento}%`}
                            </p>
                            <p className="card-text red">
                              Máx: {d.max_to_replace}
                            </p>
                          </div>
                          <div
                            className={`controls ${
                              d.enabled ? "" : "disabled"
                            }`}
                          >
                            <div
                              className={`control-button del ${
                                maxDelEnabled ? "" : "disabled"
                              }`}
                              onClick={() =>
                                maxDelEnabled &&
                                addAlternative(d.sku, -1 * (d.amount || 0))
                              }
                            >
                              {maxDelEnabled ? (
                                <img src={trashIcon} alt="ícono basura" />
                              ) : (
                                <i />
                              )}
                            </div>
                            <div className="counter">
                              <div
                                className={`del ${!delEnabled && "disabled"}`}
                                onClick={() =>
                                  delEnabled && addAlternative(d.sku, -1)
                                }
                              >
                                -
                              </div>
                              <div className="count">{d.amount || 0}</div>
                              <div
                                className={`add ${!addEnabled && "disabled"}`}
                                onClick={() =>
                                  addEnabled && addAlternative(d.sku, 1)
                                }
                              >
                                +
                              </div>
                            </div>
                            <div
                              className={`control-button add ${
                                maxAddEnabled ? "" : "disabled"
                              }`}
                              onClick={() =>
                                maxAddEnabled && addAlternative(d.sku, maxAdd)
                              }
                            >
                              {`${maxAddEnabled ? `+${maxAdd}` : ""}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <Modal isOpen={unlockModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Restricciones desbloqueadas
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-3">
              Selecciona una opción para justificar el desbloqueo de las
              restricciones de prioridad y/o cantidades:
            </div>
            <div className="col-12">
              <Input
                type="select"
                style={{ height: "38px" }}
                value={unlockComment}
                onChange={(e) => setUnlockComment(e.target.value)}
              >
                <option value="Diferencia WMS">Diferencia WMS</option>
                <option value="Diferencia SAP">Diferencia SAP</option>
                <option value="Producto Retenido industrial">
                  Producto Retenido industrial
                </option>
                <option value="Sobrepeso Romana">Sobrepeso Romana</option>
                <option value="Falta de parametría en SKU">
                  Falta de parametría en SKU
                </option>
                <option value="Diferencia tipo camión">
                  Diferencia tipo camión
                </option>
                <option value="Solicitud">Solicitud</option>
              </Input>
            </div>
            <div className="col-12">
              <Input
                type="text"
                style={{ height: "38px", marginTop: "20px" }}
                value={oca}
                placeholder="Ingresar OCA"
                onChange={(e) => setOca(e.target.value)}
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row w-100">
            <div className="col-6">
              <Button color="secondary" onClick={toggleModal}>
                Cancelar
              </Button>
            </div>
            <div className="col-6">
              <Button
                color="primary"
                onClick={submitReplacement}
                disabled={!validOca}
              >
                Aplicar reemplazo
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default Stock;
